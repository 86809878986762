/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
@font-face {
  font-family: 'Documenta Regular';
  src: url("../../fonts/Documenta/D019W13T.eot");
  src: url("../../fonts/Documenta/D019W13T.eot?#iefix") format("embedded-opentype"), url("../../fonts/Documenta/D019W13T.woff") format("woff"), url("../../fonts/Documenta/D019W13T.ttf") format("truetype"), url("../../fonts/Documenta/D019W13T.svg#dtl_documenta_wtregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Documenta Italic';
  src: url("../../fonts/DocumentaItalic/DTL Documenta ST Italic.otf");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Suisse Light';
  src: url("../../fonts/Suisse/SuisseIntl-Light-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Book';
  src: url("../../fonts/Suisse/SuisseIntl-Book-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Semi Bold';
  src: url("../../fonts/Suisse/SuisseIntl-SemiBold-WebS.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Bold';
  src: url("../../fonts/Suisse/SuisseIntl-Bold-WebS.woff");
  font-weight: normal;
  font-style: normal; }

.clamp {
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical; }
  .clamp-1 {
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-2 {
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-3 {
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-5 {
    -webkit-line-clamp: 5;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }
  .clamp-9 {
    -webkit-line-clamp: 9;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical; }

.column-count--2 {
  -moz-column-count: 2;
       column-count: 2;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

.column-count--3 {
  -moz-column-count: 3;
       column-count: 3;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

.column-count--4 {
  -moz-column-count: 4;
       column-count: 4;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

.column-count--5 {
  -moz-column-count: 5;
       column-count: 5;
  -moz-column-width: auto;
       column-width: auto;
  -moz-column-gap: auto;
       column-gap: auto; }

/**************/
/*** COLORS ***/
/**************/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.image-banner {
  position: relative; }
  .not-fw .image-banner > .row {
    margin-right: 0;
    margin-left: 0; }
  .image-banner .mainbanner, .image-banner .mainbanner-figure, .image-banner .mainbanner-image.stretch {
    width: 100%; }
  .image-banner .image-heading-container {
    padding: 0;
    position: static; }
    .image-banner .image-heading-container .image-heading-text h1,
    .image-banner .image-heading-container .image-heading-text h2,
    .image-banner .image-heading-container .image-heading-text h3,
    .image-banner .image-heading-container .image-heading-text h4 {
      margin-bottom: 0;
      text-align: inherit; }
      .image-banner .image-heading-container .image-heading-text h1:last-of-type,
      .image-banner .image-heading-container .image-heading-text h2:last-of-type,
      .image-banner .image-heading-container .image-heading-text h3:last-of-type,
      .image-banner .image-heading-container .image-heading-text h4:last-of-type {
        margin-bottom: 2.5rem; }
    .image-banner .image-heading-container .image-heading-text p {
      margin: 0;
      text-align: inherit; }
    .image-banner .image-heading-container .image-heading-text.science-heading h1,
    .image-banner .image-heading-container .image-heading-text.science-heading h2,
    .image-banner .image-heading-container .image-heading-text.science-heading h3,
    .image-banner .image-heading-container .image-heading-text.science-heading h4 {
      font-family: "Suisse Light", "Suisse Book", sans-serif; }
    .image-banner .image-heading-container .image-heading-text.science-heading h3 {
      font-size: 1.5625rem; }
    .image-banner .image-heading-container .image-heading-text.science-heading h4 {
      font-size: 1.25rem; }
    @media (max-width: 767.98px) {
      .image-banner .image-heading-container .image-heading-text.mobile-brand-heading h1,
      .image-banner .image-heading-container .image-heading-text.mobile-brand-heading h2,
      .image-banner .image-heading-container .image-heading-text.mobile-brand-heading h3,
      .image-banner .image-heading-container .image-heading-text.mobile-brand-heading h4 {
        font-family: "Documenta Regular", serif; } }
    @media (max-width: 767.98px) {
      .image-banner .image-heading-container .image-heading-text.mobile-science-heading h1,
      .image-banner .image-heading-container .image-heading-text.mobile-science-heading h2,
      .image-banner .image-heading-container .image-heading-text.mobile-science-heading h3,
      .image-banner .image-heading-container .image-heading-text.mobile-science-heading h4 {
        font-family: "Suisse Light", "Suisse Book", sans-serif; }
      .image-banner .image-heading-container .image-heading-text.mobile-science-heading h3 {
        font-size: 1.5625rem; }
      .image-banner .image-heading-container .image-heading-text.mobile-science-heading h4 {
        font-size: 1.25rem; } }
    .image-banner .image-heading-container .heading-btns {
      display: block;
      margin-top: 2.5rem;
      margin-bottom: 0.9375rem;
      white-space: normal; }
      .image-banner .image-heading-container .heading-btns .btn {
        margin-bottom: 0.625rem; }
        .image-banner .image-heading-container .heading-btns .btn:first-child {
          margin-right: 1rem; }
    .image-banner .image-heading-container .heading-inner {
      text-align: left; }
      .image-banner .image-heading-container .heading-inner.left {
        text-align: left; }
      .image-banner .image-heading-container .heading-inner.right {
        text-align: right; }
      .image-banner .image-heading-container .heading-inner.center {
        text-align: center; }
      @media (max-width: 767.98px) {
        .image-banner .image-heading-container .heading-inner.mobile-left {
          text-align: left; } }
      @media (max-width: 767.98px) {
        .image-banner .image-heading-container .heading-inner.mobile-right {
          text-align: right; } }
      @media (max-width: 767.98px) {
        .image-banner .image-heading-container .heading-inner.mobile-center {
          text-align: center; } }
    .image-banner .image-heading-container.dark .image-heading-text {
      color: #000; }
      .image-banner .image-heading-container.dark .image-heading-text P {
        color: #000; }
    .image-banner .image-heading-container.light .image-heading-text {
      color: #fff; }
      .image-banner .image-heading-container.light .image-heading-text P {
        color: #fff; }
    @media (max-width: 767.98px) {
      .image-banner .image-heading-container.mobile-dark .image-heading-text {
        color: #000; }
        .image-banner .image-heading-container.mobile-dark .image-heading-text P {
          color: #000; } }
    @media (max-width: 767.98px) {
      .image-banner .image-heading-container.mobile-light .image-heading-text {
        color: #fff; }
        .image-banner .image-heading-container.mobile-light .image-heading-text P {
          color: #fff; } }
    .image-banner .image-heading-container.text-below {
      padding-top: 1rem; }
      .image-banner .image-heading-container.text-below .heading-inner {
        display: inline-block; }
      .image-banner .image-heading-container.text-below.left .image-heading-text {
        text-align: left; }
      .image-banner .image-heading-container.text-below.right .image-heading-text {
        text-align: right; }
      .image-banner .image-heading-container.text-below.center .image-heading-text {
        text-align: center; }
    .image-banner .image-heading-container.text-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }
      .image-banner .image-heading-container.text-overlay > .container {
        position: relative;
        height: 100%;
        width: 100%; }
      .image-banner .image-heading-container.text-overlay .image-heading-text {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: left;
        margin: 0;
        max-width: 100%; }
        @media (min-width: 544px) {
          .image-banner .image-heading-container.text-overlay .image-heading-text {
            width: auto; } }
      .image-banner .image-heading-container.text-overlay .heading-btns {
        margin-top: 2.5rem; }
        .image-banner .image-heading-container.text-overlay .heading-btns .cta-btn {
          margin-bottom: 0; }
      .image-banner .image-heading-container.text-overlay.top .image-heading-text {
        top: 1rem;
        left: 50%;
        transform: translateX(-50%);
        text-align: center; }
      .image-banner .image-heading-container.text-overlay.right .image-heading-text {
        right: 0;
        transform: translateY(-50%);
        text-align: right; }
      .image-banner .image-heading-container.text-overlay.bottom .image-heading-text {
        top: unset;
        bottom: 5vh;
        left: 50%;
        transform: translateX(-50%);
        text-align: center; }
      .image-banner .image-heading-container.text-overlay.left .image-heading-text {
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        text-align: left; }
      .image-banner .image-heading-container.text-overlay.center .image-heading-text {
        top: 50%;
        left: 50%;
        text-align: center;
        transform: translate(-50%, -50%); }
      .image-banner .image-heading-container.text-overlay.top.right .image-heading-text {
        top: 5vh;
        right: 0;
        left: unset;
        transform: unset;
        text-align: right; }
      .image-banner .image-heading-container.text-overlay.top.left .image-heading-text {
        top: 5vh;
        left: 0;
        transform: unset;
        text-align: left; }
      .image-banner .image-heading-container.text-overlay.bottom.right .image-heading-text {
        top: unset;
        bottom: 5vh;
        right: 0;
        left: unset;
        transform: unset;
        text-align: right; }
      .image-banner .image-heading-container.text-overlay.bottom.left .image-heading-text {
        top: unset;
        bottom: 5vh;
        right: unset;
        left: 0;
        transform: unset;
        text-align: left; }
      .image-banner .image-heading-container.text-overlay.left.center .image-heading-text {
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        text-align: left; }
        @media (min-width: 768px) {
          .image-banner .image-heading-container.text-overlay.left.center .image-heading-text {
            left: 25%;
            transform: translate(-50%, -50%);
            left: 2rem;
            transform: translate(0, -50%); } }
      .image-banner .image-heading-container.text-overlay.right.center .image-heading-text {
        top: 50%;
        left: unset;
        right: 0;
        transform: translateY(-50%);
        text-align: right; }
        @media (min-width: 544px) {
          .image-banner .image-heading-container.text-overlay.right.center .image-heading-text {
            right: 25%;
            transform: translate(50%, -50%);
            text-align: left; } }
      @media (max-width: 767.98px) {
        .image-banner .image-heading-container.text-overlay.mobile-alignment.pos-top .image-heading-text {
          top: 1rem;
          left: 50%;
          transform: translateX(-50%);
          text-align: center; }
        .image-banner .image-heading-container.text-overlay.mobile-alignment.pos-right .image-heading-text {
          right: 0;
          transform: translateY(-50%);
          text-align: right; }
        .image-banner .image-heading-container.text-overlay.mobile-alignment.pos-bottom .image-heading-text {
          top: unset;
          bottom: 5vh;
          left: 50%;
          transform: translateX(-50%);
          text-align: center; }
        .image-banner .image-heading-container.text-overlay.mobile-alignment.pos-left .image-heading-text {
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          text-align: left; }
        .image-banner .image-heading-container.text-overlay.mobile-alignment.pos-center .image-heading-text {
          top: 50%;
          left: 50%;
          text-align: center;
          transform: translate(-50%, -50%); }
        .image-banner .image-heading-container.text-overlay.mobile-alignment.pos-top-right .image-heading-text {
          top: 5vh;
          right: 0;
          left: unset;
          transform: unset;
          text-align: right; }
        .image-banner .image-heading-container.text-overlay.mobile-alignment.pos-top-left .image-heading-text {
          top: 5vh;
          left: 0;
          transform: unset;
          text-align: left; }
        .image-banner .image-heading-container.text-overlay.mobile-alignment.pos-bottom-right .image-heading-text {
          top: unset;
          bottom: 5vh;
          right: 0;
          left: unset;
          transform: unset;
          text-align: right; }
        .image-banner .image-heading-container.text-overlay.mobile-alignment.pos-bottom-left .image-heading-text {
          top: unset;
          bottom: 5vh;
          right: unset;
          left: 0;
          transform: unset;
          text-align: left; }
        .image-banner .image-heading-container.text-overlay.mobile-alignment.pos-center-left .image-heading-text {
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          text-align: left; }
        .image-banner .image-heading-container.text-overlay.mobile-alignment.pos-center-right .image-heading-text {
          top: 50%;
          left: unset;
          right: 0;
          transform: translateY(-50%);
          text-align: right; } }
  .image-banner .hasVideo {
    position: relative;
    overflow: hidden; }
    .image-banner .hasVideo.light {
      background-color: var(--color-black); }
    .image-banner .hasVideo.dark {
      background-color: var(--color-white); }
    .image-banner .hasVideo video {
      width: 100%;
      max-width: 100%;
      height: auto;
      top: 0;
      left: 0;
      margin: 0;
      -o-object-fit: contain;
         object-fit: contain; }
  .image-banner.dynamic-cat-banner .image-heading-container:not(.text-overlay) .image-heading-text h1,
  .image-banner.dynamic-cat-banner .image-heading-container:not(.text-overlay) .image-heading-text p {
    color: var(--skin-primary-color); }
  .image-banner.dynamic-cat-banner .image-heading-container .page-heading-wrapper,
  .image-banner.dynamic-cat-banner .image-heading-container .image-heading-text {
    padding: 0; }
    .image-banner.dynamic-cat-banner .image-heading-container .page-heading-wrapper h1,
    .image-banner.dynamic-cat-banner .image-heading-container .image-heading-text h1 {
      margin-bottom: 0;
      text-align: inherit; }
      .image-banner.dynamic-cat-banner .image-heading-container .page-heading-wrapper h1:last-of-type,
      .image-banner.dynamic-cat-banner .image-heading-container .image-heading-text h1:last-of-type {
        margin-bottom: 0.9375rem; }
        @media (min-width: 768px) {
          .image-banner.dynamic-cat-banner .image-heading-container .page-heading-wrapper h1:last-of-type,
          .image-banner.dynamic-cat-banner .image-heading-container .image-heading-text h1:last-of-type {
            margin-bottom: 1.75rem; } }
    .image-banner.dynamic-cat-banner .image-heading-container .page-heading-wrapper p,
    .image-banner.dynamic-cat-banner .image-heading-container .image-heading-text p {
      margin: 0;
      text-align: inherit; }
      @media (min-width: 768px) {
        .image-banner.dynamic-cat-banner .image-heading-container .page-heading-wrapper p,
        .image-banner.dynamic-cat-banner .image-heading-container .image-heading-text p {
          font-family: "Documenta Regular", serif;
          font-weight: 400;
          font-size: 1.5rem;
          line-height: 2.0625rem; } }
